import React from 'react';
import {
  translate,
  Create,
  DisabledInput,
  Datagrid,
  SimpleForm,
  minValue,
  Edit,
  Toolbar,
  SaveButton,
  EditButton,
  NumberInput,
  FormTab,
  BooleanInput,
  Filter,
  TabbedForm,
  ImageInput,
  ImageField,
  TextField,
  TextInput,
  required,
} from 'react-admin';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/CardGiftcard';

import Poster from '../posters/Poster';
import PosterAr from '../posters/PosterAr';
export const GiftSettingIcon = Icon;

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const GiftSettingFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label='pos.search' source='q' alwaysOn /> */}
    {/* <TextInput label='pos.search' source='gift' value='1' /> */}
  </Filter>
);

export const GiftSettingList = (props) => {
  return (
    <List
      filterDefaultValues={{ gift: 1 }}
      pagination={false}
      {...props}
      filters={<GiftSettingFilter />}
    >
      <Datagrid>
        <TextField source='name' />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const handleChangeImage = (evt) => {};

export const GiftSettingCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <DisabledInput source='name' />
          <ImageInput
            source='image'
            label='The photo'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Arabic Content'>
          <DisabledInput source='name_ar' />
          <ImageInput
            source='image_ar'
            label='The photo'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_ar' title='title' />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const GiftSettingTitle = translate(({ record, translate }) => (
  <span>
    {translate('gallery.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));
export const GiftSettingEdit = withStyles(editStyles)(
  ({ classes, ...props }) => {
    return (
      <Edit {...props} id='7' title={<GiftSettingTitle />}>
        <SimpleForm redirect='list' toolbar={<CustomToolbar />}>
          <h1>Ad properties</h1>
          <BooleanInput label='Enable ad photo' source='has_ad' />
          {/* <DisabledInput source='name' /> */}
          <Poster />
          <PosterAr />
          <h1 style={{ marginTop: '50px' }}>Gift properties</h1>
          <BooleanInput label='Enable gift scenario' source='has_gift' />
          <NumberInput
            validate={[minValue(1)]}
            label='Gifts number'
            source='count'
          />
          <NumberInput
            validate={[minValue(1)]}
            label='Order minimum price to apply gift'
            source='value'
          />
        </SimpleForm>
      </Edit>
    );
  },
);
