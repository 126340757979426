import React, {Component} from 'react';

import {greatPlaceStyle} from './my_great_place_styles.js';
import Icon from '@material-ui/icons/LocationOn';
import SvgIcon from '@material-ui/core/SvgIcon';

export default class MyGreatPlace extends Component {
 

  static defaultProps = {};

  render() {
    return (
       <div style={greatPlaceStyle}>
          <SvgIcon>
  {/* credit: plus icon from https://heroicons.com/ */}
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
    strokeLinecap="round"
      strokeLinejoin="round"
     d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/>
  </svg>
</SvgIcon>
       </div>
    );
  }
}