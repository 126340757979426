import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  ArrayField,
  FunctionField,
  DeleteButton,
  AutocompleteInput,
  Filter,
  SimpleForm,
  NumberInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  DisabledInput,
  SelectInput,
  TextField,
  ReferenceField,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Money';
import ApproveButton from './ApproveButton';
import RejectButton from './RejectButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const TransactionsIcon = Icon;

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const TransactionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='q' alwaysOn />
    <ReferenceInput
      source='user_id'
      reference='users'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <AutocompleteInput source='name' enableSearch={true} />
    </ReferenceInput>
    <DateInput source='ordered_on' label='Paid on' />
    <DateInput source='from_date' label='From date' />
    <DateInput source='to_date' label='To date' />
    <ReferenceInput
      source='city_id'
      reference='cities'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput source='name' />
    </ReferenceInput>

    {/* <QuickFilter
      label="resources.Transactions.fields.stock_lte"
      source="stock_lte"
      defaultValue={10}
    /> */}
  </Filter>
);

const RowStyle = (record, index) => ({
  backgroundColor:
    record.order_status === 'canceled'
      ? '#eaad'
      : record.order_status === 'pending_cancel'
      ? '#e4ce38'
      : 'white',
});

export const TransactionsList = (props) => (
  <List
    perPage={25}
    title='Transactions'
    {...props}
    filters={<TransactionsFilter />}
  >
    <Datagrid rowStyle={RowStyle}>
      <ReferenceField
        label='Order ID'
        source='id'
        reference='orders'
        linkType='show'
      >
        <TextField source='daily_id' />
      </ReferenceField>
      <ReferenceField
        label='User'
        source='user_id'
        reference='users'
        linkType='show'
      >
        <TextField source='name' />
      </ReferenceField>

      <TextField
        source='payment_method'
        style={{ textTransform: 'capitalize' }}
      />
      <TextField source='grand_totlal' label='Paid Amount' />
      <TextField source='order_status' />
      <TextField source='created_at' />
      <TextField source='city_name' label='City' />
      {/* <ShowButton />
      <ApproveButton />
      <RejectButton /> */}
      {/* <FunctionField label="Progress" render={function render(record) { 
        // ( record.order_status == "Initiated" )  
         <DeleteButton/>
          // : false
        }} /> */}
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};
