export default {
  palette: {
    primary: {
      main: '#ff0266', // Not far from red
    },
    secondary: {
      light: '#6ec6ff',
      main: '#6ec6ff',
      dark: '#0069c0',
      contrastText: '#fff',
    },
  },
  typography: {
    title: {
      fontWeight: 400,
    },
  },
};
