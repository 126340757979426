import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from 'redux-form';
import TextField from 'material-ui/TextField';
import Switch from '@material-ui/core/Switch';
import submit from './submit';
import { createMuiTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import red from '@material-ui/core/colors/red';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import { DateInput, TimeInput } from 'react-admin-date-inputs';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { blue } from '@material-ui/core/colors';
const required = (value) => (value ? undefined : 'Required');
const notDecimal = (value) =>
  value % 1 != 0 ? 'Only non decimal numbers' : undefined;
const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength99 = maxLength(99);
const maxLength100 = maxLength(100);

const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
const minValue1 = minValue(1);

const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const themE = createMuiTheme({});
const styles = (theme) => ({
  card: {
    maxWidth: '100%',
    padding: '20px',
  },
  actions: {
    display: 'flex',
  },
  header: {
    fontSize: 50,
  },
  label: {
    color: blue,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  input_hidden: {
    display: 'none !important',
  },
  sync_button: {
    marginLeft: '5px',
  },
  swich_button: {
    color: 'blue !important',
    marginLeft: '4px',
    span: {
      color: 'blue !important',
    },
  },
  notification_swich_button: {
    color: 'blue !important',
    marginLeft: '4px',
    span: {
      color: 'blue !important',
    },
  },
  swich_button_hidden: {
    color: 'blue !important',
    display: 'none',
    marginLeft: '4px',
    span: {
      color: 'blue !important',
    },
  },
  notification_swich_button_hidden: {
    color: 'blue !important',
    display: 'none',
    marginLeft: '4px',
    span: {
      color: 'blue !important',
    },
  },
});

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'firstName',
    'lastName',
    'discount',
    'email',
    'favoriteColor',
    'notes',
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  requiredFields.forEach((field) => {
    if (values.discount < 0) {
      errors.discount = 'Minimum and disable is 0';
    }
    if (values.discount > 100) {
      errors.discount = 'Maximum  100%';
    }
  });

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

const renderRadio = ({ input, label, meta: { touched, error }, ...custom }) => (
  <Switch
    hintText={label}
    checked={input.value}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

const Styles = {
  editor: {
    border: '1px solid gray',
    minHeight: '6em',
  },
};

class GeneralNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMassege: false,
      regNotification: 'false',
      classHeddin: 'input_hidden',
      classWorkingHeddin: 'input_hidden',
    };
  }

  async componentDidMount() {
    this.handleInitialize();
  }

  handleInitialize() {
    const request = new Request(
      process.env.REACT_APP_CLIENT_URL + '/api/homeData?data=1',
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-Authorization-Token': '12b20fa6cca0ee113dc92d16f6be3029',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      },
    );
    return fetch(request)
      .then((response) => response.json())
      .then((data) => {
        const initData = {
          about: data.about,
          contacts: data.contacts,
          about_ar: data.about_ar,
          contacts_ar: data.contacts_ar,
          facebook: data.facebook,
          instagram: data.instagram,
          snapchat: data.snapchat,
          open_from: data.open_from,
          open_to: data.open_to,
          closed: data.closed,
          closedMessage: data.closedMessage,
          closedMessage_en: data.closedMessage_en,
          closedMessageOutTime: data.closedMessageOutTime,
          register_discount_notification_val:
            data.register_discount_notification_val,
          register_discount_notification: data.register_discount_notification,
          serve_until: data.serve_until,
          valid_until: data.valid_until,
          discount: data.discount,
          loyalty_points_factor: data.loyalty_points_factor,
          loyalty_on: data.loyalty_on,
          serve_until_en: data.serve_until_en,
          closedMessageOutTime_en: data.closedMessageOutTime_en,
          loyalty_points_expiration: data.loyalty_points_expiration,
          loyalty_minimum_to_redeem: data.loyalty_minimum_to_redeem,
          tax: data.tax,
          loyalty_promo_amount: data.loyalty_promo_amount,
        };
        this.setState({
          loyaltyOn: data.loyalty_on ? true : false,
        });
        if (data.register_discount_notification === true) {
          this.setState({
            regNotification: 'false',
            regNotificationHeddin: 'notification_swich_button',
          });
        } else {
          this.setState({
            regNotification: 'true',
            regNotificationHeddin: 'notification_swich_button_hidden',
          });
        }

        if (data.discount > 0) {
          this.setState({ classHeddin: 'input' });
        }
        if (!data.closed || data.closed === 'false') {
          this.setState({
            classWorkingHeddin: 'swich_button',
            closedState: 'false',
          });
        } else {
          this.setState({
            classWorkingHeddin: 'swich_button_hidden',
            closedState: 'true',
          });
        }
        this.props.initialize(initData);
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleSunc = (event) => {
    this.setState({ openMassege: true });

    const request = new Request(
      process.env.REACT_APP_CLIENT_URL + '/api/sync',
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-Authorization-Token': '12b20fa6cca0ee113dc92d16f6be3029',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      },
    );
    fetch(request)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ openMassege: false });
      });
  };

  render() {
    const { classes, submitting, handleSubmit } = this.props;
    let setDisabled = localStorage.getItem('editorAdmin') != 1 ? false : true;
    return (
      <Card className={classes.card}>
        <CardHeader
          title='General Setting'
          className={classes.header}
          style={{ fontSize: '42px' }}
        />
        <MuiThemeProvider theme={themE}>
          <div>
            <form onSubmit={handleSubmit(submit)}>
              {!setDisabled && (
                <>
                  <div>
                    <Field
                      name='about'
                      fullWidth={true}
                      multiLine={true}
                      rows={3}
                      rowsMax={5}
                      component={renderTextField}
                      label='About section'
                    />
                  </div>
                  <div>
                    <Field
                      name='about_ar'
                      fullWidth={true}
                      multiLine={true}
                      rows={3}
                      rowsMax={5}
                      component={renderTextField}
                      label='About section in ARABIC'
                    />
                  </div>
                  <div>
                    <Field
                      name='contacts'
                      fullWidth={true}
                      multiLine={true}
                      rows={3}
                      rowsMax={5}
                      component={renderTextField}
                      label='Contacts section'
                    />
                  </div>
                  <div>
                    <Field
                      name='contacts_ar'
                      fullWidth={true}
                      rowsMax={5}
                      multiLine={true}
                      rows={3}
                      component={renderTextField}
                      label='Contacts section in ARABIC'
                    />
                  </div>
                  <div>
                    <Field
                      name='facebook'
                      fullWidth={true}
                      component={renderTextField}
                      label='Facebook URL'
                    />
                  </div>
                  <div>
                    <Field
                      name='instagram'
                      fullWidth={true}
                      component={renderTextField}
                      label='Instagram URL'
                    />
                  </div>
                  <div>
                    <Field
                      name='snapchat'
                      fullWidth={true}
                      component={renderTextField}
                      label='Snapchat URL'
                    />
                  </div>

                  <div>
                    <Field
                      name='discount'
                      type='number'
                      min={0}
                      component={renderTextField}
                      onChange={(event) => {
                        if (event.target.value > 0) {
                          this.setState({ classHeddin: 'input' });
                        } else {
                          this.setState({ classHeddin: 'input_hidden' });
                        }
                      }}
                      label='Global cart discount %'
                    />
                  </div>

                  <div>
                    <DateInput
                      className={classes[this.state.classHeddin]}
                      label='Valid until'
                      source='valid_until'
                      options={{ disablePast: true }}
                    />
                  </div>

                  <div>
                    <FormControlLabel
                      control={
                        <Field
                          name='closed'
                          component={renderRadio}
                          onChange={(event) => {
                            if (this.state.closedState === 'true') {
                              this.setState({
                                classWorkingHeddin: 'swich_button',
                              });
                              this.setState({ closedState: 'false' });
                            } else {
                              this.setState({
                                classWorkingHeddin: 'swich_button_hidden',
                              });
                              this.setState({ closedState: 'true' });
                            }
                          }}
                        />
                      }
                      label='Close ( Stop receive orders ) '
                      labelPlacement='start'
                      classes={{
                        label: classes.swich_button,
                      }}
                      className={classes.swich_button}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      // className={classes[this.state.classWorkingHeddin]}
                      control={
                        <div>
                          <TimeInput
                            // className={classes[this.state.classWorkingHeddin]}
                            label='From'
                            source='open_from'
                          />
                          <TimeInput
                            // className={classes[this.state.classWorkingHeddin]}
                            label='To'
                            source='open_to'
                          />
                        </div>
                      }
                      label='Working hours '
                      labelPlacement='start'
                      classes={{
                        label:
                          this.state.closedState === 'false'
                            ? classes.swich_button
                            : classes.swich_button_hidden,
                      }}
                      className={
                        this.state.closedState === 'false'
                          ? classes.swich_button
                          : classes[this.state.classWorkingHeddin]
                      }
                    />
                  </div>
                  <div>
                    <Field
                      name='closedMessage'
                      fullWidth={true}
                      component={renderTextField}
                      label='Closed (Stop receiving orders message ) In Arabic'
                      className={
                        this.state.closedState === 'true'
                          ? classes.input
                          : classes.input_hidden
                      }
                    />
                  </div>
                  <div>
                    <Field
                      name='closedMessage_en'
                      fullWidth={true}
                      component={renderTextField}
                      label='Closed (Stop receiving orders message ) In English'
                      className={
                        this.state.closedState === 'true'
                          ? classes.input
                          : classes.input_hidden
                      }
                    />
                  </div>
                  <div>
                    <Field
                      className={
                        this.state.closedState === 'true'
                          ? classes.input_hidden
                          : classes.input
                      }
                      name='closedMessageOutTime'
                      fullWidth={true}
                      component={renderTextField}
                      label='Message for stop receiving orders out of working hours in Arabic!'
                    />
                  </div>
                  <div>
                    <Field
                      className={
                        this.state.closedState === 'true'
                          ? classes.input_hidden
                          : classes.input
                      }
                      name='closedMessageOutTime_en'
                      fullWidth={true}
                      component={renderTextField}
                      label='Message for stop receiving orders out of working hours in English!'
                    />
                  </div>

                  <div>
                    <Field
                      name='serve_until'
                      fullWidth={true}
                      component={renderTextField}
                      label='Area out of devivery service time (Area out of serve until time ) in Arabic'
                    />
                  </div>
                  <div>
                    <Field
                      name='serve_until_en'
                      fullWidth={true}
                      component={renderTextField}
                      label='Area out of devivery service time (Area out of serve until time ) in English'
                    />
                  </div>
                  <div
                    style={{ border: '1px solid #e0e0e0', marginBottom: '5px' }}
                  >
                    <div>
                      <FormControlLabel
                        control={
                          <Field
                            name='register_discount_notification'
                            component={renderRadio}
                            onChange={(event) => {
                              if (this.state.regNotification === 'true') {
                                this.setState({
                                  regNotificationHeddin:
                                    'notification_swich_button',
                                });
                                this.setState({ regNotification: 'false' });
                              } else {
                                this.setState({
                                  regNotificationHeddin:
                                    'notification_swich_button_hidden',
                                });
                                this.setState({ regNotification: 'true' });
                              }
                            }}
                          />
                        }
                        label='First register discount notification ( Send notification to the user who just registered)'
                        labelPlacement='start'
                        classes={{
                          label: classes.notification_swich_button,
                        }}
                        className={classes.notification_swich_button}
                      />
                    </div>
                    <div>
                      <Field
                        className={
                          this.state.regNotification === 'true'
                            ? classes.input_hidden
                            : classes.input
                        }
                        type='number'
                        max={99}
                        min={1}
                        style={{ marginLeft: '5px', marginTop: '-25px' }}
                        name='register_discount_notification_val'
                        fullWidth={false}
                        component={renderTextField}
                        label='Discount percentage %'
                      />
                    </div>
                  </div>

                  <div style={{ border: '1px solid #e0e0e0', padding: '10px' }}>
                    <h2 style={{ marginLeft: '22px' }}>Loyalty setting</h2>
                    <div>
                      <FormControlLabel
                        control={
                          <Field
                            name='loyalty_on'
                            component={renderRadio}
                            onChange={(event) => {
                              if (this.state.loyaltyOn) {
                                this.setState({
                                  classWorkingHeddin: 'swich_button',
                                });
                                this.setState({ loyaltyOn: false });
                              } else {
                                this.setState({
                                  classWorkingHeddin: 'swich_button_hidden',
                                });
                                this.setState({ loyaltyOn: true });
                              }
                            }}
                          />
                        }
                        label='On ( Start to collect points from orders ) '
                        labelPlacement='start'
                        classes={{
                          label: classes.swich_button,
                        }}
                        className={classes.swich_button}
                      />
                    </div>
                    <div
                      style={{
                        display: this.state.loyaltyOn ? 'block' : 'none',
                      }}
                    >
                      <div>
                        <Field
                          name='loyalty_points_factor'
                          fullWidth={true}
                          validate={
                            this.state.loyaltyOn
                              ? [required, number, minValue1, notDecimal]
                              : []
                          }
                          component={renderTextField}
                          label='Money to Points factor (every 1 EGP = X points)'
                        />
                      </div>
                      {/* <div>
                    <Field
                      name='loyalty_points_expiration'
                      fullWidth={true}
                      component={renderTextField}
                      validate={
                        this.state.loyaltyOn
                          ? [required, number, minValue1, notDecimal]
                          : []
                      }
                      label='Points expiration (In days)'
                    />
                  </div> */}
                      <div>
                        <Field
                          name='loyalty_minimum_to_redeem'
                          fullWidth={true}
                          component={renderTextField}
                          validate={
                            this.state.loyaltyOn
                              ? [required, number, minValue1, notDecimal]
                              : []
                          }
                          label='Minimum points to redeem'
                        />
                      </div>
                      <div>
                        <Field
                          name='loyalty_promo_amount'
                          fullWidth={true}
                          component={renderTextField}
                          validate={
                            this.state.loyaltyOn
                              ? [required, number, minValue1]
                              : []
                          }
                          label='PromoCode amount of redeemed points : Every Minimum points = X EGP'
                        />
                      </div>
                    </div>
                  </div>
                  {/* 
              <div>
                <Field
                    name='tax'
                    type='number'
                    min={0}
                    max={100}
                    component={renderTextField}
                    label='Tax percentage (% from total order)'
                />
              </div> */}
                  <div>
                    <FormControlLabel
                      control={
                        <Field
                          name='stop_pos_ordering'
                          component={renderRadio}
                          onChange={(event) => {
                            if (this.state.regNotification === 'true') {
                              this.setState({
                                regNotificationHeddin:
                                  'notification_swich_button',
                              });
                              this.setState({ regNotification: 'false' });
                            } else {
                              this.setState({
                                regNotificationHeddin:
                                  'notification_swich_button_hidden',
                              });
                              this.setState({ regNotification: 'true' });
                            }
                          }}
                        />
                      }
                      label='Stop Sending Orders To POS'
                      labelPlacement='start'
                      classes={{
                        label: classes.notification_swich_button,
                      }}
                      className={classes.notification_swich_button}
                    />
                  </div>

                  <div>
                    <Button
                      color='primary'
                      variant='contained'
                      type='submit'
                      label='Save'
                      disabled={submitting}
                    >
                      <SaveIcon />
                      Save
                    </Button>

                    <Button
                      className={classes.sync_button}
                      color='secondary'
                      variant='contained'
                      label='Sunc'
                      disabled={this.state.openMassege}
                      onClick={() => {
                        this.handleSunc();
                      }}
                    >
                      <SyncIcon />
                      Sync POS Data
                    </Button>
                  </div>
                </>
              )}
              {setDisabled && (
                <div>
                  <FormControlLabel
                    control={
                      <Field
                        name='closed'
                        component={renderRadio}
                        onChange={(event) => {
                          if (this.state.closedState === 'true') {
                            this.setState({
                              classWorkingHeddin: 'swich_button',
                            });
                            this.setState({ closedState: 'false' });
                          } else {
                            this.setState({
                              classWorkingHeddin: 'swich_button_hidden',
                            });
                            this.setState({ closedState: 'true' });
                          }
                        }}
                      />
                    }
                    label='Close ( Stop receive orders ) '
                    labelPlacement='start'
                    classes={{
                      label: classes.swich_button,
                    }}
                    className={classes.swich_button}
                  />
                  <Button
                    color='primary'
                    variant='contained'
                    type='submit'
                    label='Save'
                    disabled={submitting}
                  >
                    <SaveIcon />
                    Save
                  </Button>
                </div>
              )}
            </form>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              message={<span id='message-id'>Saving ... !</span>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={this.state.openMassege}
              autoHideDuration={6000}
              message={<span id='message-id'>Syncing ... !</span>}
            />
          </div>
        </MuiThemeProvider>
      </Card>
    );
  }
}

export default reduxForm({
  form: 'GeneralNotification',
  validate,
})(withStyles(styles)(GeneralNotification));
