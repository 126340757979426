import React, { Component } from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import TextField from 'material-ui/TextField';
import submit from './submit';
import { createMuiTheme } from '@material-ui/core/styles';
import RaisedButton from 'material-ui/RaisedButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import red from '@material-ui/core/colors/red';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import FileBase64 from 'react-file-base64';

const themE = createMuiTheme({
  // palette: {
  //   primary: 'dark'
  // }
});

const styles = (theme) => ({
  card: {
    maxWidth: '100%',
    padding: '20px',
  },
  actions: {
    display: 'flex',
  },
  header: {
    color: 'green',
    marginTop: '20px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

const validate = (values) => {
  const errors = {};
  const requiredFields = ['title', 'body'];
  requiredFields.forEach((field) => {
    if (field === 'title' && values[field] && values[field].length !== 11) {
      errors[field] = 'Mobile number should be 11 number';
    }
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
);

class GeneralNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMassege: false,
    };
    this.getFiles = this.getFiles.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    console.log('Submitted!');
  };
  getFiles(files) {
    console.log(this.props);
    let titleVal = document.getElementById('title').value;
    let bodyVal = document.getElementById('body').value;
    this.setState({ files: files.base64 });
    this.props.initialize({
      image: files.base64,
      title: titleVal,
      body: bodyVal,
    });
  }

  render() {
    const {
      classes,
      theme,
      error,
      submitting,
      reset,
      handleSubmit,
    } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title='Send SMS to a phone number'
          className={classes.header}
          style={{ fontSize: '42px' }}
        />
        <MuiThemeProvider theme={themE}>
          <div>
            <form onSubmit={handleSubmit(submit)}>
              <div>
                <Field
                  name='title'
                  id='title'
                  fullWidth={true}
                  type='number'
                  component={renderTextField}
                  label='Phone number'
                />
              </div>
              <div>
                <Field
                  name='body'
                  id='body'
                  fullWidth={true}
                  multiLine={true}
                  rows={3}
                  component={renderTextField}
                  label='Message'
                />
              </div>
              <div>
                <RaisedButton
                  color='primary'
                  type='submit'
                  label='Send'
                  disabled={submitting}
                  primary={true}
                />
              </div>
            </form>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              message={<span id='message-id'>Sending ... !</span>}
            />
          </div>
        </MuiThemeProvider>
      </Card>
    );
  }
}
const afterSubmit = (result, dispatch) =>
  dispatch(reset('GeneralNotification'));
export default reduxForm({
  form: 'GeneralNotification', // a unique identifier for this form
  validate,
  onSubmitSuccess: (result, dispatch) => {
    dispatch(reset('GeneralNotification'));
  },
})(withStyles(styles)(GeneralNotification));
