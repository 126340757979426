import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  ReferenceArrayField,
  SingleFieldList,
  EditButton,
  Toolbar,
  SaveButton,
  ChipField,
  ReferenceArrayInput,
  FunctionField,
  BooleanInput,
  ImageInput,
  SelectArrayInput,
  CheckboxGroupInput,
  ImageField,
  FormTab,
  DisabledInput,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import List from '../customs/List';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Category';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Poster from '../posters/Poster';
import PosterAr from '../posters/PosterAr';

export const ProductCategoryIcon = Icon;
function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}
export const ProductCategoryList = (props) => (
  <List perPage={25} {...props}>
    <Datagrid>
      <TextField source='name' />
      <ReferenceArrayField label="Hidden In" reference="cities" source="hide" >
      <SingleFieldList>
          <ChipField source="name" />
      </SingleFieldList>
  </ReferenceArrayField>
{/* 
      <FunctionField
          label='Hidden'
          render={(record) => booleanCheck(record.hide)}
        /> */}
      <EditButton />
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const ProductCategoryCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput source='name' />
          <ImageInput source='image' label='The photo' accept='image/*'>
            <ImageField source='image' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput source='name_ar' />
          <ImageInput source='image_ar' label='The photo' accept='image/*'>
            <ImageField source='image_ar' title='title' />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const ProductCategoryTitle = translate(({ record, translate }) => (
  <span>
    {translate('ProductCategory.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
export const ProductCategoryEdit = withStyles(editStyles)(
  ({ classes, ...props }) => {
    return (
    <Edit {...props} title={<ProductCategoryTitle />}>
      <TabbedForm redirect='list' toolbar={<CustomToolbar />}>
        <FormTab label='English Content'>
          <DisabledInput source='name' />

          <Poster />
        </FormTab>
        <FormTab label='Arabic Content'>
          <DisabledInput source='name_ar' />
          <PosterAr />
        </FormTab>
        <FormTab label='Category properties'>
          <ReferenceArrayInput
            label='Hide from Mobile App in cities :'
            reference='cities'
            style={{width:'100%'}}
            source='hide'
          >
            <CheckboxGroupInput>
              <ChipField source='hide' />
            </CheckboxGroupInput>
          </ReferenceArrayInput>

        </FormTab>
      </TabbedForm>
    </Edit>
  )},
);
