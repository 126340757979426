import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// import Logo from './logo.png';

const styles = {
  MyAppBar: {
    backgroundColor: '#84bf33',
    // position: 'relative',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logoImage: {
    // backgroundColor: '#fff',
    width: '52px',
    // padding: '8px',
    // marginLeft: '180px',
    // borderRadius: '14px',
  },
  selectOption: {
    color: '#fff',
  },
  barSelect: {
    color: '#fff',
    height: '48px !important',
    '& label': {
      top: '12px !important',
      color: 'white !important',
    },
    '& option': {
      color: '#fff',
      backgroundColor: '#8b58a4',
    },
    '& svg': {
      color: '#fff',
    },
    '& select': {
      padding: '14px 24px 7px',
    },
  },
};

const MyAppBar = withStyles(styles)(({ classes, ...props }) => (
  <AppBar className={classes.MyAppBar} {...props}>
    <Typography
      variant='title'
      color='inherit'
      className={classes.title}
      id='react-admin-title'
    />
    <img className={classes.logoImage} src='logo.png' />
    <span className={classes.spacer} />
  </AppBar>
));

export default MyAppBar;
