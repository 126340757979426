import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  ArrayField,
  FunctionField,
  DeleteButton,
  AutocompleteInput,
  Filter,
  SimpleForm,
  NumberInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  DisabledInput,
  SelectInput,
  TextField,
  ReferenceField,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Loyalty';
import Poster from './Poster';
import DoneButton from './doneButton';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
export const UserPointsIcon = Icon;

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));
function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}
export const UserPointsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='user_id'
      reference='users'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <AutocompleteInput source='name' enableSearch={true} />
    </ReferenceInput>
    <DateInput source='ordered_on' label='Ordered on' />
    <DateInput source='from_date' label='From date' />
    <DateInput source='to_date' label='To date' />

    {/* <QuickFilter
      label="resources.UserPoints.fields.stock_lte"
      source="stock_lte"
      defaultValue={10}
    /> */}
  </Filter>
);

const RowStyle = (record, index) => ({
  backgroundColor: record.order_status === 'canceled' ? '#eaad' : 'white',
});

export const UserPointsList = (props) => (
  <List
    perPage={25}
    title='UserPoints'
    {...props}
    filters={<UserPointsFilter />}
  >
    <Datagrid rowStyle={RowStyle}>
      <TextField source='id' label='ID' />
      <ReferenceField
        label='User'
        source='user_id'
        reference='users'
        linkType={localStorage.getItem('subAdmin') !== '1' ? 'show' : false}
      >
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField
        label='Order'
        source='order_id'
        reference='orders'
        linkType='show'
      >
        <TextField source='id' />
      </ReferenceField>

      <TextField source='amount' label='Converted amount' />
      <TextField source='factor' label='Factor' />
      <TextField source='points' label='Granted points' />

      <FunctionField
        label='Notified'
        render={(record) => booleanCheck(record.notified)}
      />
      <FunctionField
        label='Expired'
        render={(record) => booleanCheck(record.expired)}
      />
      <TextField source='expieration_date' label='Expiration date' />
      <TextField source='created' />
      <DeleteButton />
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const handleChangeImage = (evt) => {};

export const UserPointsCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props} title='Create porduct'>
      <SimpleForm redirect='list'>
        <TextInput source='name' label='Product name' />
        <ReferenceInput
          source='product_category_id'
          label='Product category'
          reference='productCategory'
        >
          <SelectInput optionText='name' optionValue='id' />
        </ReferenceInput>
        <NumberInput source='price' />
        <BooleanInput label='In stock' source='in_stock' />
        <BooleanInput label='Featured' source='featured' />
        <ImageInput source='image' label='Related pictures' accept='image/*'>
          <ImageField source='image' title='title' />
        </ImageInput>
      </SimpleForm>
    </Create>
  ),
);

const UserPointsTitle = translate(({ record, translate }) => (
  <span>
    {translate('UserPoints.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  input2: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& p': {},
  },
  input2: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  bold: {
    fontWeight: 'bold',
  },
};

export const UserPointsEdit = withStyles(editStyles)(
  ({ classes, ...props }) => (
    <Edit {...props} title={<UserPointsTitle />}>
      <SimpleForm>
        <Poster />
        <ImageInput
          source='image'
          onChange={handleChangeImage}
          label='Related pictures'
          accept='image/*'
        >
          <ImageField
            source='image'
            onChange={handleChangeImage}
            title='title'
          />
        </ImageInput>
        <DisabledInput label='Id' source='id' />
        <TextInput source='name' validate={required()} />

        <NumberInput source='price' />
        <BooleanInput label='In stock' source='in_stock' />
        <BooleanInput label='Featured' source='featured' />
        <ReferenceInput
          source='product_category_id'
          label='Product category'
          reference='productCategory'
        >
          <SelectInput optionText='name' optionValue='id' />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  ),
);

export const OrderShow = withStyles(editStyles)(({ classes, ...props }) => (
  <Show {...props} actions={false}>
    <SimpleShowLayout>
      <TextField
        className={classes.input2}
        style={{ display: 'inline' }}
        source='id'
      />
      <TextField className={classes.input2} source='promo_code' />
      <TextField
        className={classes.input2}
        source='promo_voucher'
        label='Voucher used?'
      />
      <TextField className={classes.input2} source='general_discount' />
      <TextField
        className={classes.input2}
        source='total_price'
        title='Total before discount'
      />
      <TextField
        className={classes.input2}
        source='total_after_discount'
        title='Total before discount (if exists)'
      />
      <TextField
        className={classes.input2}
        source='fees'
        title='Delivery fees'
      />
      <TextField
        className={classes.input2}
        source='grand_totlal'
        label='Grand total'
      />
      <ReferenceField
        label='User'
        source='user_id'
        reference='users'
        linkType='show'
      >
        <TextField source='name' />
      </ReferenceField>
      <TextField
        className={classes.input}
        source='address'
        label='User Address'
      />

      <TextField className={classes.bold} source='order_status' />

      <TextField className={classes.bold} source='notes' />

      <ArrayField source='order_detailes' label='Products'>
        <Datagrid>
          <ReferenceField
            label='Product name'
            source='product_id'
            reference='products'
            linkType='show'
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField source='quantity' />
          <TextField source='price' />
          <TextField source='notes' />
          <ArrayField source='order_products_addition' label='Adds'>
            <Datagrid>
              <ReferenceField
                label='Add name'
                source='product_addition_id'
                reference='productsAddition'
                linkType='show'
              >
                <TextField source='name' />
              </ReferenceField>
              <TextField source='price' />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>

      {/* <ReferenceManyField
        label="Comments by"
        reference="products"
        source="order_detailes"
        target="id"
      >
          <ChipField source="name" />
          <Datagrid >
                    <TextField source="name" />
                    <ReferenceField
                      label="User"
                      source="product_id"
                      reference="products"
                      linkType="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    
                    
                </Datagrid>
      </ReferenceManyField> */}

      {/* <TextField className={classes.input} source="landline_number" required />
        <TextField className={classes.input} source="mobile_number" required />
        <TextField className={classes.input} source="address" required />
        <TextField className={classes.input} label="Password" source="password" type="password" />
        <Poster />
        <BooleanField source="is_admin" />
        <BooleanField source="confirmed" /> */}
    </SimpleShowLayout>
  </Show>
));
