import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  EditButton,
  DeleteButton,
  FormTab,
  Filter,
  TabbedForm,
  ImageInput,
  ImageField,
  FunctionField,
  TextField,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin';

import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Collections';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

import List from '../customs/List';
import Poster from '../posters/Poster';
import PosterAr from '../posters/PosterAr';
export const HomeSliderIcon = Icon;

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const HomeSliderFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);
const listStyles = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  image: {
    width: '20px',
  },
  CardActions: {
    disblay: 'none',
  },
};

function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}
export const HomeSliderList = withStyles(listStyles)(
  ({ classes, ...props }) => (
    <List perPage={25} {...props} filters={<HomeSliderFilter />}>
      <Datagrid>
        <TextField source='title' />
        <ImageField
          source='photo'
          classes={{
            image: classes.image,
          }}
          title='picture.title'
        />
        <FunctionField
          label='For mobile'
          render={(record) => booleanCheck(record.for_mobile)}
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  ),
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const HomeSliderCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput source='title' validate={required()} />
          <ImageInput
            source='image'
            label='Slider photo'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput source='title_ar' validate={required()} />
          <ImageInput
            source='image_ar'
            label='Slider photo in Arabic'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_ar' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Slider setting'>
          <BooleanInput
            label='Is it for mobile?'
            source='for_mobile'
            defaultValue='0'
            validate={required()}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const HomeSliderTitle = translate(({ record, translate }) => (
  <span>
    {translate('homeSlider.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export const HomeSliderEdit = withStyles(editStyles)(
  ({ classes, ...props }) => (
    <Edit {...props} title={<HomeSliderTitle />}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput source='title' />
          <Poster />
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput source='title_ar' />
          <PosterAr />
        </FormTab>
        <FormTab label='Slider setting'>
          <BooleanInput
            label='Is it for mobile?'
            source='for_mobile'
            validate={required()}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  ),
);
