import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  EditButton,
  ArrayField,
  DeleteButton,
  ArrayInput,
  TopToolbar,
  SimpleForm,
  Filter,
  List,
  SelectInput,
  BooleanField,
  Show,
  SimpleShowLayout,
  ShowButton,
  ImageInput,
  ImageField,
  BooleanInput,
  required,
  maxLength,
  minLength,
  SimpleFormIterator,
  number,
  GET_ONE,
  TextField,
  TextInput,
  CardActions,
  CreateButton,
  RefreshButton,
} from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/PhotoAlbum';
import Poster from './Poster';
import SendNotification from './SendNotification';
import { DateInput } from 'react-admin-date-inputs';
export const UsersIcon = Icon;

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <DateInput source='ordered_on' label='Orders from date' />
    <DateInput source='ordered_to' label='Orders to date' />
    <SelectInput
      alwaysOn
      label='User Type'
      source='user_type'
      choices={[
        { id: 'is_admin', name: 'Admin' },
        { id: 'is_editor', name: 'Editor' },
        { id: 'is_sub_admin', name: 'Sub Admin' },
      ]}
    />
  </Filter>
);

const UserActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {localStorage.getItem('editorAdmin') != 1 && (
      <CreateButton basePath={basePath} />
    )}
    <RefreshButton />
  </CardActions>
);

export const UsersList = (props) => (
  <List
    perPage={25}
    {...props}
    actions={<UserActions />}
    filters={<UserFilter />}
  >
    <Datagrid>
      <TextField source='id' />
      <TextField source='name' />
      {localStorage.getItem('editorAdmin') != 1 && (
        <TextField source='points' />
      )}
      <TextField source='mobile_number' />
      {localStorage.getItem('editorAdmin') != 1 && (
        <TextField source='orders_count' />
      )}
      {localStorage.getItem('editorAdmin') != 1 && (
        <TextField source='total_paid' />
      )}
      {localStorage.getItem('editorAdmin') != 1 && (
        <TextField
          source='user_type'
          sortable={false}
          style={{ color: '#84bf33' }}
        />
      )}
      <TextField source='created_at' label='Registered on' />
      {localStorage.getItem('editorAdmin') != 1 && <SendNotification />}
      <ShowButton />
      <EditButton />
      {localStorage.getItem('editorAdmin') != 1 && <DeleteButton />}
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
  button: { margin: '1em' },
  input: { width: '500px !important' },
};
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function postData(data, id, email) {
  let idd = id ? id : 0;
  let Email = email ? email : 0;
  let url =
    process.env.REACT_APP_CLIENT_URL +
    '/api/checkMobileNo/' +
    data +
    '?user_id=' +
    idd +
    '?email=' +
    Email;
  // Default options are marked with *
  return fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'X-Device': 'front',
      'X-lang': 'en',

      // "Content-Type": "application/x-www-form-urlencoded",
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    //        body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then((response) => response.json()); // parses response to JSON
}

const asyncValidate = async (values, dispatch, props, field) => {
  return new Promise(async (resolve, reject) => {
    let err = props.asyncErrors ? props.asyncErrors : [];
    if (field === 'mobile_number') {
      const currentFieldValue = values.mobile_number;
      const currentFieldName = field;
      const data = await postData(values.mobile_number, values.id);
      if (data.success === false) {
        err.mobile_number = 'The mobile number is already exsits!';
        reject(err);
      } else {
        if (Object.keys(err).length !== 0) {
          err.splice('mobile_number', 1);
        }
      }
    }
    if (field === 'email') {
      const currentFieldValue = values.mobile_number;
      const currentFieldName = field;
      const data = await postData(
        // values.mobile_number,
        values.email,
        values.id,
      );
      if (data.success === false) {
        err.email = data.message;
        reject(err);
      } else {
        if (Object.keys(err).length !== 0) {
          err.splice('email', 1);
        }
      }
    }
    if (Object.keys(err).length === 0) {
      resolve();
    }
  });
};
export const UsersCreate = withStyles(createStyles)(({ classes, ...props }) => (
  <Create {...props}>
    <SimpleForm
      redirect='list'
      asyncValidate={asyncValidate}
      asyncBlurFields={['mobile_number', 'email']}
    >
      <TextInput
        className={classes.input}
        source='name'
        validate={required()}
      />
      <TextInput
        className={classes.input}
        source='email'
        validate={required()}
      />
      <TextInput
        className={classes.input}
        source='mobile_number'
        validate={[required(), number(), minLength(11), maxLength(11)]}
      />
      <TextInput
        className={classes.input}
        label='Password'
        source='password'
        type='password'
        validate={required()}
      />
      <TextInput
        className={classes.input}
        label='Re-Password'
        source='c_password'
        type='password'
        validate={passConfirmValidate}
      />
      <SelectInput
        source='gender'
        choices={[
          { id: 'male', name: 'Male' },
          { id: 'female', name: 'Female' },
        ]}
        validate={required()}
      />
      <ImageInput source='image' label='User photo' accept='image/*'>
        <ImageField source='image' title='title' />
      </ImageInput>
      <BooleanInput source='is_admin' />
      <BooleanInput source='is_sub_admin' />
      <BooleanInput source='is_editor' />
      <BooleanInput source='confirmed' />
    </SimpleForm>
  </Create>
));

const UsersTitle = translate(({ record, translate }) => (
  <span>User ({record.name})</span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    input: { width: '500px !important' },
  },
};

const passValidation = (value, allValues) => {
  if (allValues.password != value) {
    return "Password confirmation doesn't match with password";
  }
  return undefined;
};
const passConfirmValidate = [passValidation];

const AddressEditor = ({ classes, record }) => (
  <ArrayInput source='addresses' options={{ fullWidth: true }}>
    <SimpleFormIterator disableRemove disableAdd options={{ fullWidth: true }}>
      <SelectInput
        source='city_id'
        label='City'
        choices={record.cities}
        validate={required()}
        fullWidth
      ></SelectInput>
      <SelectInput
        source='area_id'
        label='Area'
        choices={record.areas}
        validate={required()}
        fullWidth
      ></SelectInput>
      <TextInput source='building' options={{ fullWidth: true }} fullWidth />
      <TextInput source='description' fullWidth />
      <TextInput source='floor' fullWidth />
      {/* <TextInput source='sign' fullWidth /> */}
      <TextInput source='street' fullWidth />
    </SimpleFormIterator>
  </ArrayInput>
);
var isDisabled = localStorage.getItem('editorAdmin') == 1 ? true : false;
var isDisabled = false;

export const UsersEdit = withStyles(editStyles)(({ classes, ...props }) => (
  <Edit toolbar={false} title={<UsersTitle />} {...props}>
    <SimpleForm
      redirect='list'
      asyncValidate={asyncValidate}
      asyncBlurFields={['mobile_number', 'email']}
    >
      {!isDisabled && <Poster />}
      <TextInput
        disabled={isDisabled}
        className={classes.input}
        source='name'
      />
      {!isDisabled && (
        <TextInput
          className={classes.input}
          source='email'
          autocomplete={false}
          validate={required()}
        />
      )}
      <TextInput
        className={classes.input}
        disabled={isDisabled}
        source='mobile_number'
        autocomplete='off'
        validate={[required(), number(), minLength(11), maxLength(11)]}
      />
      {!isDisabled && (
        <>
          <TextInput
            className={classes.input}
            label='Password'
            source='password'
            type='password'
          />
          <TextInput
            className={classes.input}
            label='Re-Password'
            source='c_password'
            type='password'
            validate={passConfirmValidate}
          />
          <SelectInput
            source='gender'
            choices={[
              { id: 'male', name: 'Male' },
              { id: 'female', name: 'Female' },
            ]}
            validate={required()}
          />
          <ImageInput source='image' label='User photo' accept='image/*'>
            <ImageField source='image' title='title' />
          </ImageInput>
          <BooleanInput source='is_admin' />
          <BooleanInput source='is_sub_admin' />
          <BooleanInput source='is_editor' />
          <BooleanInput source='confirmed' />
        </>
      )}
      <AddressEditor />
    </SimpleForm>
  </Edit>
));

export const UserShow = withStyles(editStyles)(({ classes, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField className={classes.input} source='name' />
      <TextField className={classes.input} source='email' required />
      <TextField className={classes.input} source='landline_number' required />
      <TextField className={classes.input} source='mobile_number' required />
      <TextField className={classes.input} source='gender' required />
      <ArrayField source='addresses' label='User Addresses'>
        <Datagrid>
          <TextField source='city_name' />
          <TextField source='area_name' />
          <TextField source='street' />
          <TextField source='floor' />
          <TextField source='building' />
          <TextField source='description' />
          <TextField source='sign' />
          <TextField source='latitude' />
          <TextField source='longitude' />
        </Datagrid>
      </ArrayField>
      <Poster />
      <BooleanField source='is_admin' />
      <BooleanField source='is_sub_admin' />
      <BooleanField source='confirmed' />
    </SimpleShowLayout>
  </Show>
));
