import React from 'react';
import Card from '@material-ui/core/Card';
import ShoppingCartIcon from '@material-ui/icons/AttachMoney';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';

import CardIcon from './CardIcon';

const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const BiggestOrderUser = ({ value, translate, classes }) => (
    <div className={classes.main}>
        {console.log(value)}
        <CardIcon Icon={ShoppingCartIcon} bgColor="green" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                User of biggest order value
            </Typography>
            <Typography variant="headline" component="h2">
                 {(value && value.user) && value.user.name} ({(value) && parseInt(value.total_after_discount) + parseInt(value.fees) } EGP)
            </Typography>
        </Card>
    </div>
);

export default translate(withStyles(styles)(BiggestOrderUser));
