import React from 'react';
import { Datagrid, TextInput, Filter, TextField } from 'react-admin';
import List from '../customs/List';
import Icon from '@material-ui/icons/NotificationsActive';
export const NotificationsIcon = Icon;

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    {/* <TextInput label="Title" source="title" defaultValue="Hello, World!" /> */}
  </Filter>
);

export const NotificationsList = (props) => (
  <List perPage={25} {...props} filters={<UserFilter />}>
    {/* {console.log(props)} */}
    <Datagrid>
      <TextField source='id' />
      <TextField source='title' />
      <TextField source='body' />
      <TextField source='ios' label='Iphone' />
      <TextField source='android' />
      <TextField source='created_at' label='Date' />
      {/* <DeleteButton /> */}
    </Datagrid>
  </List>
);
