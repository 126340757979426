import React, { Component } from 'react';
import Board from 'react-trello';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { SelectInput, SimpleForm } from 'react-admin';

const themE = createMuiTheme({});
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const styles = (theme) => ({
  card: {
    maxWidth: '100%',
    padding: '20px',
  },

  header: {
    fontSize: 50,
  },
});

var isEqual = function(value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function(item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

class ProdcutsOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      items: false,
    };
  }

  async componentDidMount() {
    this.handleInitialize();
  }

  handleInitialize(city_id) {
    var city_id = city_id ? city_id : 0;
    localStorage.setItem('city_id', city_id);
    const request = new Request(
      process.env.REACT_APP_CLIENT_URL +
        '/api/prodcutsNcategories?city_id=' +
        city_id,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-Authorization-Token': '12b20fa6cca0ee113dc92d16f6be3029',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      },
    );
    return fetch(request)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ items: data });
      });
  }

  sendData(data) {
    if (isEqual(data, this.state.items)) {
      return false;
    }
    console.log(data);
    return sleep(1000).then(() => {
      var city_id = localStorage.getItem('city_id');
      const request = new Request(
        process.env.REACT_APP_CLIENT_URL + '/api/saveSort?city_id=' + city_id,
        {
          method: 'POST',
          body: JSON.stringify({ data }),
          headers: new Headers({
            'Content-Type': 'application/json',
            'X-Authorization-Token': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Client-Type': 'web',
            'Access-Control-Allow-Origin': '*',
          }),
        },
      );
      return fetch(request);
    });
  }

  onDataChange = (handle) => {
    this.sendData(handle);
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          title='Products and Ctegories Sorting'
          className={classes.header}
          style={{ fontSize: '42px' }}
        />
        <SimpleForm
          redirect='list'
          onChange={(event) => {
            this.handleInitialize(event.type);
          }}
          toolbar={false}
        >
          <SelectInput
            source='type'
            defaultValue={1}
            choices={[
              { id: 1, name: 'Tanta' },
              { id: 2, name: 'Zagazig' },
              { id: 5, name: 'Mahallah' },
              { id: 6, name: 'Cairo' },
            ]}
          />
        </SimpleForm>
        <MuiThemeProvider theme={themE}>
          {this.state.items ? (
            <Board
              data={this.state.items}
              onDataChange={this.onDataChange}
              draggable
            />
          ) : (
            false
          )}
        </MuiThemeProvider>
      </Card>
    );
  }
}
export default withStyles(styles)(ProdcutsOrder);
