import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Toolbar,
  SaveButton,
  FormTab,
  Filter,
  TabbedForm,
  NumberInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  SelectArrayInput,
  TextField,
  LongTextInput,
  TextInput,
  required,
} from 'react-admin';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/AddToPhotos';
import Poster from '../posters/Poster';
export const ProductsAdditionCategoryIcon = Icon;

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const ProductsAdditionCategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='q' alwaysOn />
    <ReferenceInput
      source='category_id'
      reference='categories'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectArrayInput source='name' />
    </ReferenceInput>
    <NumberInput source='width_gte' />
    <NumberInput source='width_lte' />
    <NumberInput source='height_gte' />
    <NumberInput source='height_lte' />
    <QuickFilter
      label='resources.ProductsAdditionCategory.fields.stock_lte'
      source='stock_lte'
      defaultValue={10}
    />
  </Filter>
);

export const ProductsAdditionCategoryList = (props) => (
  <List perPage={25} {...props}>
    <Datagrid>
      <TextField source='name' />
      <ImageField source='photo' title='picture.title' />

      {/* <ReferenceManyField
        label="For products"
        source="product_id"
        target="product_idss"
        reference="Products"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField> */}
      <EditButton />
      {/* <DeleteButton /> */}
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const handleChangeImage = (evt) => {};

export const ProductsAdditionCategoryCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props} title='Create porduct addition'>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput
            source='name'
            className={classes.fullWidth}
            label='Addition name'
            validate={required()}
          />
          <LongTextInput source='description' label='Description' />
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput
            source='name_ar'
            className={classes.fullWidth}
            label='Addition name'
            validate={required()}
          />
          <LongTextInput source='description_ar' label='Description' />
        </FormTab>
        <FormTab label='Addition Properties'>
          <ImageInput source='image' label='The photo' accept='image/*'>
            <ImageField source='image' title='title' />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const ProductsAdditionCategoryTitle = translate(({ record, translate }) => (
  <span>
    {translate('ProductsAdditionCategory.name')} ({record.name})
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export const ProductsAdditionCategoryEdit = withStyles(editStyles)(
  ({ classes, ...props }) => (
    <Edit {...props} title={<ProductsAdditionCategoryTitle />}>
      <TabbedForm redirect='list' toolbar={<CustomToolbar />}>
        <FormTab label='English Content'>
          <TextInput
            source='name'
            className={classes.fullWidth}
            label='Addition name'
            validate={required()}
          />
          <LongTextInput source='description' label='Description' />
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput
            source='name_ar'
            className={classes.fullWidth}
            label='Addition name'
            validate={required()}
          />
          <LongTextInput source='description_ar' label='Description' />
        </FormTab>
        <FormTab label='Addition Properties'>
          <Poster />
        </FormTab>
      </TabbedForm>
    </Edit>
  ),
);
