import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  ReferenceInput,
  EditButton,
  DisabledInput,
  minLength,
  RadioButtonGroupInput,
  Filter,
  SimpleForm,
  AutocompleteInput,
  regex,
  TextInput,
  Toolbar,
  SaveButton,
  required,
  TextField,
  FormDataConsumer,
} from 'react-admin';
import { TimeInput } from 'react-admin-date-inputs';
import List from '../customs/List';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/LocationOn';
import moment from 'moment';
export const AreasIcon = Icon;

export const OrdersFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='city_id'
      reference='cities'
      alwaysOn
      // sort={{ field: 'id', order: 'ASC' }}
    >
      <AutocompleteInput source='name' enableSearch={true} />
    </ReferenceInput>
  </Filter>
);

export const AreasList = (props) => (
  <List perPage={25} {...props} filters={<OrdersFilter />}>
    {/* {console.log(props)} */}
    <Datagrid>
      <TextField source='name' />
      <TextField source='city_name' sortable={false} />
      <TextField source='branch_name' sortable={false} />
      <TextField source='fees' />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.is_24) {
            formData.serve_until = '24 Open';
          }
        }}
      </FormDataConsumer>
      <TextField source='serve_until' />
      <EditButton />
      {/* <DeleteButton /> */}
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const AreasCreate = withStyles(createStyles)(({ classes, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <DisabledInput className={classes.input} source='name' required />
      <DisabledInput className={classes.input} source='name_ar' />
      <DisabledInput className={classes.input} source='fees' required />
      <TimeInput
        className={classes.input}
        label='Serve Until'
        source='serve_until'
        required
      />
    </SimpleForm>
  </Create>
));

const AreasTitle = translate(({ record, translate }) => (
  <span>
    {translate('Areas.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const isCorrectFormat = (dateString, format) => {
  return moment(dateString, format, true).isValid();
};

export const AreasEdit = withStyles(editStyles)(({ classes, ...props }) => (
  <Edit {...props} title={<AreasTitle />}>
    <SimpleForm redirect='list' toolbar={<CustomToolbar />}>
      <ReferenceInput
        source='branch_id'
        reference='branches'
        validate={[required()]}
      >
        <AutocompleteInput source='name' enableSearch={true} required />
      </ReferenceInput>
      <DisabledInput className={classes.input} source='name' required />
      <DisabledInput className={classes.input} source='name_ar' />
      <DisabledInput className={classes.input} source='fees' />
      <TextInput
        className={classes.input}
        source='route_distance'
        label='Route Distance in KM'
        validate={[required(), regex(/^\d*\.?\d*$/, 'Must be valid number')]}
      />

      <RadioButtonGroupInput
        source='pickup_only'
        label='Enalbe pickup only'
        defaultValue={1}
        choices={[{ id: '1', name: 'Yes' }, { id: '0', name: 'No' }]}
      />
      <RadioButtonGroupInput
        source='is_24'
        label='Is opened 24 hour?'
        defaultValue={1}
        choices={[{ id: '1', name: 'Yes' }, { id: '0', name: 'No' }]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          console.log(formData.serve_until);
          console.log(isCorrectFormat(formData.serve_until, 'h:mm A'));
          if (isCorrectFormat(formData.serve_until, 'h:mm A')) {
            console.log(
              moment(formData.serve_until, ['h:mm A']).format('HH:mm'),
            );
            formData.serve_until = moment(formData.serve_until, [
              'h:mm A',
            ]).format();
          }
          // formData.serve_until  = 111;
          return (
            formData.is_24 === '0' && (
              <TimeInput
                className={classes.input}
                source='serve_until'
                label='Serve Until'
                required
              />
            )
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
));
