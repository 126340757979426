import React, { Component, Fragment } from 'react';
import { GET_LIST, GET_MANY, Responsive, Title } from 'react-admin';

import Welcome from './Welcome';
import TotalUsers from './TotalUsers';
import MostOrderedUser from './MostOrderedUser';
import BiggestOrderUser from './BiggestOrderUser';
import TotalOrders from './TotalOrders';
import LastSync from './LastSync';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import dataProviderFactory from '../dataProvider';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {
  state = {};

  componentDidMount() {
    const aMonthAgo = new Date();
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);

    dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
      (dataProvider) => {
        dataProvider(GET_LIST, 'statistics', {
          filter: { date_gte: aMonthAgo.toISOString() },
          sort: { field: 'date', order: 'DESC' },
          pagination: { page: 1, perPage: 50 },
        }).then((response) => {
          if (response.status === 403) {
            throw new Error('This user is not an Administrator');
          }
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }

          // console.log(response.data.most_ordered_user);
          var chartData = [];
          if (response.data.chart) {
            response.data.chart.map((item, i) => {
              var elment = { name: item.date, orders: item.orders };
              chartData.push(elment);
            });
          }
          this.setState({
            users: response.data.users,
            nbMostOrderedUser: response.data.most_ordered_user,
            nbBiggestOrderUser: response.data.biggest_order_user,
            nblatest_sync: response.data.latest_sync,
            nbTotalOrders: response.data.totalOrders,
            chartData: chartData,
            pendingOrders: response.data.todayOrders,
          });
        });
      },
    );
  }

  render() {
    const {
      nbNewCustomers,
      nbMostOrderedUser,
      nbBiggestOrderUser,
      nbTotalOrders,
      nbPendingReviews,
      nblatest_sync,
      pendingOrders,
      pendingOrdersCustomers,
      pendingReviews,
      pendingReviewsCustomers,
      users,
    } = this.state;
    return (
      <Fragment>
        <Title title='Crepe And Waffle Admin panel' />
        <Responsive
          xsmall={
            <div>
              <div style={styles.flexColumn}>
                <div style={{ marginBottom: '2em' }}>{/* <Welcome /> */}</div>
                <div style={styles.flex}>
                  <TotalUsers value={users} />
                  <MostOrderedUser value={nbMostOrderedUser} />
                </div>
                <div style={styles.singleCol}>
                  <PendingOrders orders={pendingOrders} />
                </div>
              </div>
            </div>
          }
          small={
            <div style={styles.flexColumn}>
              <div style={styles.singleCol}>{/* <Welcome /> */}</div>
              <div style={styles.flex}>
                <TotalUsers value={users} />
                <MostOrderedUser value={nbMostOrderedUser} />
              </div>
              {/* <div style={styles.singleCol}>
                <PendingOrders
                  orders={pendingOrders}
                  customers={pendingOrdersCustomers}
                />
              </div> */}
            </div>
          }
          medium={
            <div>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <div style={styles.flex}>
                    <TotalUsers value={users} />
                    <TotalOrders value={nbTotalOrders} />
                  </div>
                  <div style={styles.singleCol}>
                    <PendingOrders orders={pendingOrders} />
                  </div>
                  {/* <div style={styles.singleCol}>
                  <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                  />
                </div> */}
                </div>
                <div style={styles.rightCol}>
                  <div style={styles.singleCol}>
                    <MostOrderedUser value={nbMostOrderedUser} />
                  </div>
                  <div style={styles.singleCol}>
                    <BiggestOrderUser value={nbBiggestOrderUser} />
                  </div>
                  <div style={styles.singleCol}>
                    <LastSync value={nblatest_sync} />
                  </div>
                </div>
              </div>
              <div style={styles.flex}>
                <ResponsiveContainer width='90%' height={300}>
                  <LineChart
                    width={700}
                    height={300}
                    data={this.state.chartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type='monotone'
                      dataKey='orders'
                      stroke='#8884d8'
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        />
      </Fragment>
    );
  }
}

export default Dashboard;
