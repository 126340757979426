import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import { ImageInput, ImageField, TextInput } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

const styles = {
  root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
  content: { padding: 0, '&:last-child': { padding: 0 } },
  img: {
    width: 'initial',
    minWidth: 'initial',
    maxWidth: '42em',
    maxHeight: '15em',
  },
};
function deletePhoto(e, id, photo) {
  e.preventDefault();
  sendDelPhoto(id, photo);
  document.getElementById('image_mobile_photo').remove();
}

function sendDelPhoto(id, photo) {
  console.log(photo);
  const request = new Request(
    process.env.REACT_APP_CLIENT_URL +
      '/api/delProductPhoto/' +
      id +
      '?type=' +
      photo,
    {
      method: 'POST',
      //   body: JSON.stringify({values}),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Authorization-Token': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Client-Type': 'web',
        'Access-Control-Allow-Origin': '*',
      }),
    },
  );
  return fetch(request);
}
const PosterMobile = withStyles(styles)(({ classes, record, ...props }) => {
  return (
    <Card className={classes.root}>
      {!props.setDisabled && (
        <ImageInput
          source='image_mobile'
          label='Photo for mobile'
          accept='image/*'
          placeholder={
            <p>
              Note maximum image size is 200K and resolution 3000X1500 px (Drop
              or click your photo)
            </p>
          }
        >
          <ImageField source='image_mobile' title='title' />
        </ImageInput>
      )}
      <CardContent className={classes.content} id='image_mobile_photo'>
        <img src={record.mobile_photo} alt='' className={classes.img} />
        {!props.setDisabled && record.mobile_photo && (
          <Button onClick={(e) => deletePhoto(e, record.id, 'mobile_photo')}>
            <DeleteIcon style={{ color: 'red' }} />
          </Button>
        )}
      </CardContent>
    </Card>
  );
});

export default PosterMobile;
