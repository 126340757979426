import { SubmissionError } from 'redux-form';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

function submit(values) {
  console.log(values);

  return sleep(1000).then(() => {
    const request = new Request(
      process.env.REACT_APP_CLIENT_URL + '/api/sendSms',
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Authorization-Token': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Client-Type': 'web',
          'Access-Control-Allow-Origin': '*',
        }),
      },
    );
    fetch(request);
  });
  return true;
}

export default submit;
