import React, { useState } from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  ReferenceField,
  NumberInput,
  SimpleForm,
  TextField,
  regex,
  minLength,
  maxLength,
  FunctionField,
  FormDataConsumer,
  required,
  RadioButtonGroupInput,
  TextInput,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import List from '../customs/List';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/CardGiftcard';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

export const RedeemsIcon = Icon;
const RowStyle = (record, index) => ({
  backgroundColor: record.expired ? '#eaad' : 'white',
});

function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}
export const RedeemsList = (props) => (
  <List perPage={25} {...props}>
    <Datagrid rowStyle={RowStyle}>
      <TextField source='promo_code' />
      <TextField source='amount' />
      <TextField source='discount_type' />
      <FunctionField
        label='One time'
        render={(record) => booleanCheck(record.used_once)}
      />
      <FunctionField
        label='Is voucher'
        render={(record) => booleanCheck(record.voucher)}
      />
      <TextField source='remain' />
      <TextField source='applied' />
      <ReferenceField
        label='User'
        source='user_id'
        reference='users'
        linkType={localStorage.getItem('subAdmin') !== '1' ? 'show' : false}
      >
        <TextField source='name' />
      </ReferenceField>
      <TextField source='expire_at' />
      <TextField source='created_at' />
      {/* <EditButton /> */}
      {/* <DeleteButton /> */}
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

function postData(promo) {
  let url = process.env.REACT_APP_CLIENT_URL + '/api/checkRedeemsCode/' + promo;
  // Default options are marked with *
  return fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'X-Device': 'front',
      'X-lang': 'en',

      // "Content-Type": "application/x-www-form-urlencoded",
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    //        body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then((response) => response.json()); // parses response to JSON
}

const asyncValidate = async (values, dispatch, props, field) => {
  return new Promise(async (resolve, reject) => {
    let err = props.asyncErrors ? props.asyncErrors : [];
    if (field === 'promo_code') {
      const currentFieldValue = values.promo_code;
      const currentFieldName = field;
      const data = await postData(values.promo_code);
      if (data.success === false) {
        err.promo_code = data.message;
        reject(err);
      } else {
        if (Object.keys(err).length !== 0) {
          err.splice('promo_code', 1);
        }
      }
    }
    if (Object.keys(err).length === 0) {
      resolve();
    }
  });
};

export const RedeemsCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <SimpleForm
        redirect='list'
        asyncValidate={asyncValidate}
        asyncBlurFields={['promo_code']}
      >
        <TextInput
          className={classes.input}
          source='promo_code'
          placeholder='(Leave to auto generate)'
          validate={[
            minLength(6),
            maxLength(10),
            regex(/^[0-9A-Za-z-]+$/, 'Only numbers and letters allowed!'),
          ]}
        />
        <RadioButtonGroupInput
          source='is_voucher'
          label='Is Voucher?'
          defaultValue='0'
          choices={[
            { id: '1', name: 'Yes' },
            { id: '0', name: 'No' },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.is_voucher === '1') {
              formData.discount_type = 'fixed';
            }
            return (
              <span>
                {formData.is_voucher === '0' ? (
                  <RadioButtonGroupInput
                    source='discount_type'
                    label='Discount type'
                    defaultValue='fixed'
                    choices={[
                      { id: 'fixed', name: 'Fixed' },
                      { id: 'percentage', name: 'Percentage' },
                    ]}
                  />
                ) : (
                  <RadioButtonGroupInput
                    source='discount_type'
                    label='Discount type'
                    defaultValue='fixed'
                    choices={[{ id: 'fixed', name: 'Fixed' }]}
                  />
                )}
              </span>
            );
          }}
        </FormDataConsumer>
        <NumberInput
          className={classes.input}
          source='amount'
          validate={required()}
        />
        <RadioButtonGroupInput
          source='used_once'
          label='Used Once?'
          defaultValue='1'
          choices={[
            { id: '1', name: 'Yes' },
            { id: '0', name: 'No' },
          ]}
        />

        <DateInput
          className={classes.input}
          label='Expire at'
          source='expire_at'
          options={{ disablePast: true }}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  ),
);

const RedeemsTitle = translate(({ record, translate }) => (
  <span>
    {translate('Redeems.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
export const RedeemsEdit = withStyles(editStyles)(({ classes, ...props }) => (
  <Edit {...props} title={<RedeemsTitle />}>
    <SimpleForm redirect='list'>
      <TextInput className={classes.input} source='name' required />
      <TextInput className={classes.input} source='name_ar' />
      <NumberInput className={classes.input} source='fees' />
      <DateInput className={classes.input} source='expire_at' required />
    </SimpleForm>
  </Edit>
));
