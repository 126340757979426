import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ListController, getListControllerProps } from 'ra-core';
import { Title } from 'react-admin';
import ListToolbar from './ListToolbar';
import DefaultPagination from './Pagination';
import DefaultBulkActionButtons from 'ra-ui-materialui/lib/button/BulkDeleteButton';
import BulkActionsToolbar from './BulkActionsToolbar';
import DefaultActions from './ListActions';
import defaultTheme from '../defaultTheme';
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
var __rest =
  (this && this.__rest) ||
  function (s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++)
        if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
    return t;
  };
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

export var styles = {
  root: {
    display: 'flex',
  },
  card: {
    position: 'relative',
    flex: '1 1 auto',
  },
  actions: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },
  noResults: { padding: 20 },
};
var sanitizeRestProps = function (_a) {
  var actions = _a.actions,
    basePath = _a.basePath,
    bulkActions = _a.bulkActions,
    changeListParams = _a.changeListParams,
    children = _a.children,
    classes = _a.classes,
    className = _a.className,
    crudGetList = _a.crudGetList,
    currentSort = _a.currentSort,
    data = _a.data,
    defaultTitle = _a.defaultTitle,
    displayedFilters = _a.displayedFilters,
    exporter = _a.exporter,
    filter = _a.filter,
    filterDefaultValues = _a.filterDefaultValues,
    filters = _a.filters,
    filterValues = _a.filterValues,
    hasCreate = _a.hasCreate,
    hasEdit = _a.hasEdit,
    hasList = _a.hasList,
    hasShow = _a.hasShow,
    hideFilter = _a.hideFilter,
    history = _a.history,
    ids = _a.ids,
    isLoading = _a.isLoading,
    locale = _a.locale,
    location = _a.location,
    match = _a.match,
    onSelect = _a.onSelect,
    onToggleItem = _a.onToggleItem,
    onUnselectItems = _a.onUnselectItems,
    options = _a.options,
    page = _a.page,
    pagination = _a.pagination,
    params = _a.params,
    permissions = _a.permissions,
    perPage = _a.perPage,
    push = _a.push,
    query = _a.query,
    refresh = _a.refresh,
    resource = _a.resource,
    selectedIds = _a.selectedIds,
    setFilters = _a.setFilters,
    setPage = _a.setPage,
    setPerPage = _a.setPerPage,
    setSelectedIds = _a.setSelectedIds,
    setSort = _a.setSort,
    showFilter = _a.showFilter,
    sort = _a.sort,
    theme = _a.theme,
    title = _a.title,
    toggleItem = _a.toggleItem,
    total = _a.total,
    translate = _a.translate,
    version = _a.version,
    rest = __rest(_a, [
      'actions',
      'basePath',
      'bulkActions',
      'changeListParams',
      'children',
      'classes',
      'className',
      'crudGetList',
      'currentSort',
      'data',
      'defaultTitle',
      'displayedFilters',
      'exporter',
      'filter',
      'filterDefaultValues',
      'filters',
      'filterValues',
      'hasCreate',
      'hasEdit',
      'hasList',
      'hasShow',
      'hideFilter',
      'history',
      'ids',
      'isLoading',
      'locale',
      'location',
      'match',
      'onSelect',
      'onToggleItem',
      'onUnselectItems',
      'options',
      'page',
      'pagination',
      'params',
      'permissions',
      'perPage',
      'push',
      'query',
      'refresh',
      'resource',
      'selectedIds',
      'setFilters',
      'setPage',
      'setPerPage',
      'setSelectedIds',
      'setSort',
      'showFilter',
      'sort',
      'theme',
      'title',
      'toggleItem',
      'total',
      'translate',
      'version',
    ]);
  return rest;
};
export var ListView = function (_a) {
  var // component props
    _b = _a.actions,
    // component props
    actions = _b === void 0 ? React.createElement(DefaultActions, null) : _b,
    aside = _a.aside,
    filters = _a.filters,
    bulkActions = _a.bulkActions, // deprecated
    _c = _a.bulkActionButtons, // deprecated
    bulkActionButtons =
      _c === void 0 ? React.createElement(DefaultBulkActionButtons, null) : _c,
    _d = _a.pagination,
    pagination =
      _d === void 0 ? React.createElement(DefaultPagination, null) : _d,
    // overridable by user
    children = _a.children,
    className = _a.className,
    classes = _a.classes,
    exporter = _a.exporter,
    title = _a.title,
    rest = __rest(_a, [
      'actions',
      'aside',
      'filters',
      'bulkActions',
      'bulkActionButtons',
      'pagination',
      'children',
      'className',
      'classes',
      'exporter',
      'title',
    ]);
  var defaultTitle = rest.defaultTitle,
    version = rest.version;
  var controllerProps = getListControllerProps(rest);
  return React.createElement(
    'div',
    __assign(
      { className: classnames('list-page', classes.root, className) },
      sanitizeRestProps(rest),
    ),
    React.createElement(Title, { title: title, defaultTitle: defaultTitle }),
    React.createElement(
      Card,
      { className: classes.card },
      bulkActions !== false &&
        bulkActionButtons !== false &&
        bulkActionButtons &&
        !bulkActions &&
        React.createElement(
          BulkActionsToolbar,
          __assign({}, controllerProps),
          bulkActionButtons,
        ),
      (filters || actions) &&
        React.createElement(
          ListToolbar,
          __assign({ filters: filters }, controllerProps, {
            actions: actions,
            bulkActions: bulkActions,
            exporter: exporter,
          }),
        ),
      React.createElement(
        'div',
        { key: version },
        children &&
          React.cloneElement(
            children,
            __assign({}, controllerProps, {
              hasBulkActions:
                bulkActions !== false && bulkActionButtons !== false,
            }),
          ),
        pagination && React.cloneElement(pagination, controllerProps),
      ),
    ),
    aside && React.cloneElement(aside, controllerProps),
  );
};
ListView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.node,
  basePath: PropTypes.string,
  bulkActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  defaultTitle: PropTypes.string,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.func,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hideFilter: PropTypes.func,
  ids: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  onUnselectItems: PropTypes.func,
  page: PropTypes.number,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  perPage: PropTypes.number,
  refresh: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.array,
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  setSort: PropTypes.func,
  showFilter: PropTypes.func,
  title: PropTypes.any,
  total: PropTypes.number,
  translate: PropTypes.func,
  version: PropTypes.number,
};
ListView.defaultProps = {
  classes: {},
};
/**
 * List page component
 *
 * The <List> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 * It then delegates the rendering of the list of records to its child component.
 * Usually, it's a <Datagrid>, responsible for displaying a table with one row for each post.
 *
 * In Redux terms, <List> is a connected component, and <Datagrid> is a dumb component.
 *
 * Props:
 *   - title
 *   - perPage
 *   - sort
 *   - filter (the permanent filter to apply to the query)
 *   - actions
 *   - filters (a React Element used to display the filter form)
 *   - pagination
 *
 * @example
 *     const PostFilter = (props) => (
 *         <Filter {...props}>
 *             <TextInput label="Search" source="q" alwaysOn />
 *             <TextInput label="Title" source="title" />
 *         </Filter>
 *     );
 *     export const PostList = (props) => (
 *         <List perPage={25}{...props}
 *             title="List of posts"
 *             sort={{ field: 'published_at' }}
 *             filter={{ is_published: true }}
 *             filters={<PostFilter />}
 *         >
 *             <Datagrid>
 *                 <TextField source="id" />
 *                 <TextField source="title" />
 *                 <EditButton />
 *             </Datagrid>
 *         </List>
 *     );
 */
export var List = function (props) {
  return React.createElement(
    ListController,
    __assign({}, props),
    function (controllerProps) {
      return React.createElement(
        ListView,
        __assign({}, props, controllerProps),
      );
    },
  );
};
List.propTypes = {
  // the props you can change
  actions: PropTypes.element,
  aside: PropTypes.node,
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  pagination: PropTypes.element,
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  title: PropTypes.any,
  // the props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  hasList: PropTypes.bool.isRequired,
  hasShow: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  path: PropTypes.string,
  resource: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};
List.defaultProps = {
  filter: {},
  perPage: 10,
  theme: defaultTheme,
};
export default withStyles(styles)(List);
